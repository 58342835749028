<template>
  <simpleNotifyPage :action="action">
    <template v-slot:subtitle>
      已提交資料給{{ layoutProviderName }},
      當供應商確認可供貨給你的商家後, 他會通知你, 就可以開始就可以開始訂購囉!
    </template>
    <template v-slot:text-label> 供應商審核狀態： </template>
    <template v-slot:text-value> 審核中 </template>
  </simpleNotifyPage>
</template>

<script>
export default {
  components: {
    simpleNotifyPage: () =>
      import("@/modules/base/views/notifyPage/simpleNotifyPage.vue"),
  },
  computed: {
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
  },
  methods: {
    action() {
      this.$helper.closeLiff();
    },
  },
};
</script>